import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';
import Helmet from 'react-helmet';
import Footer from '../containers/Portfolio/Footer';
import Image from 'common/src/components/Image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { list } from 'react-icons-kit/feather/list';
import Icon from 'react-icons-kit';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from '../../../common/src/contexts/DrawerContext';
import { portfolioTheme } from '../../../common/src/theme/portfolio';
import { ResetCSS } from '../../../common/src/assets/css/style';
import HyvorTalk from 'hyvor-talk-react';
import ContactBlogSection from '../containers/Portfolio/ContactBlog';
import {
  GlobalStyle,
  ContentWrapper
} from '../containers/Portfolio/portfolio.style';
import {
  PostContentWrapper,
  PostContentBody,
  PostContentAuthor,
  PostShare
} from '../containers/Blog/Post/post.style';

import Navbar from '../containers/Blog/Navbar';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  return (
    <ThemeProvider theme={portfolioTheme}>
      <Fragment>
        <Seo
          title={frontmatter.title.substring(0, 60)}
          description={
            frontmatter.description.substring(0, 150)
              ? frontmatter.description.substring(0, 150)
              : ''
          }
          keywords={frontmatter.keywords ? frontmatter.keywords : ''}
        />
        <Helmet>
          <meta
            property="og:image"
            content={
              'https://www.almog.io' +
              frontmatter.image.ImageUrl.childImageSharp.gatsbyImageData.src
            }
          />
          <meta
            property="og:image:secure"
            content={
              'https://www.almog.io' +
              frontmatter.image.ImageUrl.childImageSharp.gatsbyImageData.src
            }
          />
          <meta property="og:locale" content="en_US" />
          <meta
            property="og:url"
            content={'https://www.almog.io/blog/' + frontmatter.slug}
          />
          <meta property="og:title" content={frontmatter.title} />
          <meta property="og:description" content={frontmatter.description} />
        </Helmet>
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper style={{ backgroundColor: '#f9f9f9 !important' }}>
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            className="sticky-nav-page"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <PostContentWrapper>
            <PostContentBody>
              <h1 style={{ color: '#302b4e', paddingBottom: '25px' }}>
                {frontmatter.title}
              </h1>
              <PostContentAuthor>
                <Image
                  src={data.site.siteMetadata.avatar}
                  alt="Author"
                  style={{
                    width: '68px',
                    border: 'none',
                    float: 'left'
                  }}
                />
                <a
                  className="post_author"
                  href={
                    'https://twitter.com/' +
                    data.site.siteMetadata.author.slice(1)
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {data.site.siteMetadata.author}
                </a>
                <span style={{ padding: '5px' }}>&#8231;</span>
                <span className="post_date">{frontmatter.date}</span>
                <br />
                <span className="post_tags">
                  {data.markdownRemark.timeToRead} min read
                </span>{' '}
                <Icon
                  icon={list}
                  style={{
                    color: '#43414e'
                  }}
                  size={18}
                />{' '}
                <span className="post_tags">
                  {frontmatter.tags ? frontmatter.tags.join(', ') : ''}
                </span>
              </PostContentAuthor>
              <GatsbyImage
                image={
                  frontmatter.image.ImageUrl.childImageSharp.gatsbyImageData
                }
                imgStyle={{
                  display: 'block',
                  maxWidth: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                  border: '2px solid #000',
                  objectFit: 'cover'
                }}
                style={{ overflow: 'visible' }}
              />
              <div
                dangerouslySetInnerHTML={{ __html: html }}
                style={{
                  fontFamily: 'Roboto',
                  color: '#43414e',
                  paddingTop: '15px'
                }}
              />
              <br></br>
              <br></br>
              <PostShare>
                <div class="share-container">
                  <div class="share">
                    <div class="share-info">
                      <h3>Support The Blog and Share</h3>
                      <a
                        class="resp-sharing-button__link"
                        href={
                          'https://facebook.com/sharer/sharer.php?u=https://www.almog.io/blog/' +
                          frontmatter.slug
                        }
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Facebook"
                      >
                        <div class="resp-sharing-button resp-sharing-button--facebook resp-sharing-button--medium">
                          <div
                            aria-hidden="true"
                            class="resp-sharing-button__icon resp-sharing-button__icon--solid"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z" />
                            </svg>
                          </div>
                          Facebook
                        </div>
                      </a>

                      <a
                        class="resp-sharing-button__link"
                        href={
                          'https://twitter.com/intent/tweet/?text=' +
                          frontmatter.title +
                          'https://www.almog.io/blog/' +
                          frontmatter.slug
                        }
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Twitter"
                      >
                        <div class="resp-sharing-button resp-sharing-button--twitter resp-sharing-button--medium">
                          <div
                            aria-hidden="true"
                            class="resp-sharing-button__icon resp-sharing-button__icon--solid"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z" />
                            </svg>
                          </div>
                          Twitter
                        </div>
                      </a>

                      <a
                        class="resp-sharing-button__link"
                        href={
                          'https://www.linkedin.com/shareArticle?mini=true&url=https://www.almog.io/blog/' +
                          frontmatter.slug +
                          '&title=' +
                          frontmatter.title +
                          '&summary=' +
                          frontmatter.description +
                          '&source=https://www.almog.io/blog/' +
                          frontmatter.slug
                        }
                        target="_blank"
                        rel="noreferrer"
                        aria-label="LinkedIn"
                      >
                        <div class="resp-sharing-button resp-sharing-button--linkedin resp-sharing-button--medium">
                          <div
                            aria-hidden="true"
                            class="resp-sharing-button__icon resp-sharing-button__icon--solid"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M6.5 21.5h-5v-13h5v13zM4 6.5C2.5 6.5 1.5 5.3 1.5 4s1-2.4 2.5-2.4c1.6 0 2.5 1 2.6 2.5 0 1.4-1 2.5-2.6 2.5zm11.5 6c-1 0-2 1-2 2v7h-5v-13h5V10s1.6-1.5 4-1.5c3 0 5 2.2 5 6.3v6.7h-5v-7c0-1-1-2-2-2z" />
                            </svg>
                          </div>
                          LinkedIn
                        </div>
                      </a>

                      <a
                        class="resp-sharing-button__link"
                        href={
                          'https://reddit.com/submit/?url=https://www.almog.io/blog/' +
                          frontmatter.slug +
                          '&resubmit=true&title=' +
                          frontmatter.title
                        }
                        target="_blank"
                        rel="noreferrer"
                        aria-label="Reddit"
                      >
                        <div class="resp-sharing-button resp-sharing-button--reddit resp-sharing-button--medium">
                          <div
                            aria-hidden="true"
                            class="resp-sharing-button__icon resp-sharing-button__icon--solid"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 11.5c0-1.65-1.35-3-3-3-.96 0-1.86.48-2.42 1.24-1.64-1-3.75-1.64-6.07-1.72.08-1.1.4-3.05 1.52-3.7.72-.4 1.73-.24 3 .5C17.2 6.3 18.46 7.5 20 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3c-1.38 0-2.54.94-2.88 2.22-1.43-.72-2.64-.8-3.6-.25-1.64.94-1.95 3.47-2 4.55-2.33.08-4.45.7-6.1 1.72C4.86 8.98 3.96 8.5 3 8.5c-1.65 0-3 1.35-3 3 0 1.32.84 2.44 2.05 2.84-.03.22-.05.44-.05.66 0 3.86 4.5 7 10 7s10-3.14 10-7c0-.22-.02-.44-.05-.66 1.2-.4 2.05-1.54 2.05-2.84zM2.3 13.37C1.5 13.07 1 12.35 1 11.5c0-1.1.9-2 2-2 .64 0 1.22.32 1.6.82-1.1.85-1.92 1.9-2.3 3.05zm3.7.13c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9.8 4.8c-1.08.63-2.42.96-3.8.96-1.4 0-2.74-.34-3.8-.95-.24-.13-.32-.44-.2-.68.15-.24.46-.32.7-.18 1.83 1.06 4.76 1.06 6.6 0 .23-.13.53-.05.67.2.14.23.06.54-.18.67zm.2-2.8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5.7-2.13c-.38-1.16-1.2-2.2-2.3-3.05.38-.5.97-.82 1.6-.82 1.1 0 2 .9 2 2 0 .84-.53 1.57-1.3 1.87z" />
                            </svg>
                          </div>
                          Reddit
                        </div>
                      </a>

                      <a
                        class="resp-sharing-button__link"
                        href={
                          'mailto:?subject=' +
                          frontmatter.title +
                          '&amp;body=https://www.almog.io/blog/' +
                          frontmatter.slug
                        }
                        target="_blank"
                        rel="noreferrer"
                        aria-label="E-Mail"
                      >
                        <div class="resp-sharing-button resp-sharing-button--email resp-sharing-button--medium">
                          <div
                            aria-hidden="true"
                            class="resp-sharing-button__icon resp-sharing-button__icon--solid"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M22 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.25 14.43l-3.5 2c-.08.05-.17.07-.25.07-.17 0-.34-.1-.43-.25-.14-.24-.06-.55.18-.68l3.5-2c.24-.14.55-.06.68.18.14.24.06.55-.18.68zm4.75.07c-.1 0-.2-.03-.27-.08l-8.5-5.5c-.23-.15-.3-.46-.15-.7.15-.22.46-.3.7-.14L12 13.4l8.23-5.32c.23-.15.54-.08.7.15.14.23.07.54-.16.7l-8.5 5.5c-.08.04-.17.07-.27.07zm8.93 1.75c-.1.16-.26.25-.43.25-.08 0-.17-.02-.25-.07l-3.5-2c-.24-.13-.32-.44-.18-.68s.44-.32.68-.18l3.5 2c.24.13.32.44.18.68z" />
                            </svg>
                          </div>
                          E-Mail
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </PostShare>
              <br />
              <HyvorTalk.Embed websiteId={3326} />
            </PostContentBody>
          </PostContentWrapper>
          <ContactBlogSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
}
export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        author
        avatar
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        keywords
        tags
        image {
          ImageUrl {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                layout: FULL_WIDTH
              )
            }
          }
          ImageAlt
        }
      }
      timeToRead
    }
  }
`;
