import styled from 'styled-components';

export const PostContentWrapper = styled.article`
  position: relative;
  min-height: 60vh;
  width: 90%;
  max-width: 1200px;
  margin: 170px auto 50px;

  gap: 30px;
  @media (min-width: 768px) {
    font-size: 1.3rem;
    grid-template-columns: 1fr 6fr;
  }
  ul,
  ol,
  p {
    font-size: 1.2rem;
    line-height: 1.4;
  }
  li {
    list-style-type: disc;
    list-style: inside;
  }
  ul,
  ol {
    margin-left: 30px;
  }
  small {
    font-size: 1rem;
    line-height: 1.4;
  }
  header {
    margin-bottom: 2rem;
  }
  img {
    max-width: 100%;
  }
  .post_date {
    margin-top: 20px;
    color: #43414e;
    font-size: 15px;
    font-style: normal;
    text-transform: capitalize;
    line-height: 1.4;
  }
  .post_author,
  .post_link,
  .post_tags {
    color: #43414e;
    font-size: 15px;
    line-height: 1.4;
    float: 'left';
  }
`;
export const PostContentAuthor = styled.main`
  padding-bottom: 25px;
`;
export const PostContentBody = styled.main`
  max-width: 1200px;
  width: 98%;
  h1 {
    margin: 30px 0 0;
    font-size: 2rem;
    font-weight: 700;
    @media (min-width: 768px) {
      font-size: 4rem;
    }
  }
  a {
    word-break: break-all;
    color: #56aada;
  }
  img {
    border-radius: 8px;
    border: 2px solid rgb(0, 0, 0);
  }
`;

export const PostShare = styled.div`
  .resp-sharing-button__link,
  .resp-sharing-button__icon {
    display: inline-block;
  }

  .resp-sharing-button__link {
    text-decoration: none;
    color: #fff;
    margin: 0.5em;
  }

  .resp-sharing-button {
    border-radius: 5px;
    transition: 25ms ease-out;
    padding: 0.5em 0.75em;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  }

  .resp-sharing-button__icon svg {
    width: 1em;
    height: 1em;
    margin-right: 0.4em;
    vertical-align: top;
  }

  .resp-sharing-button--small svg {
    margin: 0;
    vertical-align: middle;
  }

  /* Non solid icons get a stroke */
  .resp-sharing-button__icon {
    stroke: #fff;
    fill: none;
  }

  /* Solid icons get a fill */
  .resp-sharing-button__icon--solid,
  .resp-sharing-button__icon--solidcircle {
    fill: #fff;
    stroke: none;
  }

  .resp-sharing-button--twitter {
    background-color: #55acee;
  }

  .resp-sharing-button--twitter:hover {
    background-color: #2795e9;
  }

  .resp-sharing-button--pinterest {
    background-color: #bd081c;
  }

  .resp-sharing-button--pinterest:hover {
    background-color: #8c0615;
  }

  .resp-sharing-button--facebook {
    background-color: #3b5998;
  }

  .resp-sharing-button--facebook:hover {
    background-color: #2d4373;
  }

  .resp-sharing-button--tumblr {
    background-color: #35465c;
  }

  .resp-sharing-button--tumblr:hover {
    background-color: #222d3c;
  }

  .resp-sharing-button--reddit {
    background-color: #5f99cf;
  }

  .resp-sharing-button--reddit:hover {
    background-color: #3a80c1;
  }

  .resp-sharing-button--google {
    background-color: #dd4b39;
  }

  .resp-sharing-button--google:hover {
    background-color: #c23321;
  }

  .resp-sharing-button--linkedin {
    background-color: #0077b5;
  }

  .resp-sharing-button--linkedin:hover {
    background-color: #046293;
  }

  .resp-sharing-button--email {
    background-color: #777;
  }

  .resp-sharing-button--email:hover {
    background-color: #5e5e5e;
  }

  .resp-sharing-button--xing {
    background-color: #1a7576;
  }

  .resp-sharing-button--xing:hover {
    background-color: #114c4c;
  }

  .resp-sharing-button--whatsapp {
    background-color: #25d366;
  }

  .resp-sharing-button--whatsapp:hover {
    background-color: #1da851;
  }

  .resp-sharing-button--hackernews {
    background-color: #ff6600;
  }
  .resp-sharing-button--hackernews:hover,
  .resp-sharing-button--hackernews:focus {
    background-color: #fb6200;
  }

  .resp-sharing-button--vk {
    background-color: #507299;
  }

  .resp-sharing-button--vk:hover {
    background-color: #43648c;
  }

  .resp-sharing-button--facebook {
    background-color: #3b5998;
    border-color: #3b5998;
  }

  .resp-sharing-button--facebook:hover,
  .resp-sharing-button--facebook:active {
    background-color: #2d4373;
    border-color: #2d4373;
  }

  .resp-sharing-button--twitter {
    background-color: #55acee;
    border-color: #55acee;
  }

  .resp-sharing-button--twitter:hover,
  .resp-sharing-button--twitter:active {
    background-color: #2795e9;
    border-color: #2795e9;
  }

  .resp-sharing-button--email {
    background-color: #777777;
    border-color: #777777;
  }

  .resp-sharing-button--email:hover,
  .resp-sharing-button--email:active {
    background-color: #5e5e5e;
    border-color: #5e5e5e;
  }

  .resp-sharing-button--linkedin {
    background-color: #0077b5;
    border-color: #0077b5;
  }

  .resp-sharing-button--linkedin:hover,
  .resp-sharing-button--linkedin:active {
    background-color: #046293;
    border-color: #046293;
  }

  .resp-sharing-button--reddit {
    background-color: #5f99cf;
    border-color: #5f99cf;
  }

  .resp-sharing-button--reddit:hover,
  .resp-sharing-button--reddit:active {
    background-color: #3a80c1;
    border-color: #3a80c1;
  }

  .resp-sharing-button--whatsapp {
    background-color: #25d366;
    border-color: #25d366;
  }

  .resp-sharing-button--whatsapp:hover,
  .resp-sharing-button--whatsapp:active {
    background-color: #1da851;
    border-color: #1da851;
  }

  .share {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    max-width: 100%;
    margin: 20px;
    overflow: hidden;
    width: 850px;
    margin: 0 auto;
  }

  .share h3 {
    letter-spacing: 1px;
    color: #43414e;
    text-align: center;
  }
  .share-info {
    padding: 30px;
    position: relative;
    width: 100%;
  }
`;
